import styled from "@emotion/styled";

import { color } from "metabase/lib/colors";
import { Icon } from "metabase/ui";

export const DropdownGroup = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid ${color("border")};
  }
`;

export const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-bottom: 0.5rem;
`;

export const DropdownIcon = styled(Icon)`
  margin-right: 10px;
  color: ${color("brand")};
  cursor: pointer;
`;

export const DropdownHeading = styled.h3`
  font-size: 1rem;
  line-height: 1.35rem;
  color: ${color("text-dark")};
`;

export const DropdownCounter = styled.div`
  margin-left: auto;
  padding: 2.5px 4px;
  font-size: 0.8rem;
  line-height: 0.9rem;
  font-weight: 900;
  color: ${color("text-dark")};
  background-color: #e1e2ff;
  border-radius: 4px;
`;
