import { Icon } from "metabase/ui";

import {
  GroupButtonTextContainer,
  StyledGroupButton,
} from "./GroupButton.styled";

interface IGroupButtonProps {
  name: string;
  onClick: () => void;
}

export const GroupButton = ({ name, onClick }: IGroupButtonProps) => {
  return (
    <StyledGroupButton type="button" onClick={onClick}>
      <GroupButtonTextContainer>
        <Icon name={"add"} size={16} /> {name}
      </GroupButtonTextContainer>
    </StyledGroupButton>
  );
};
