import styled from "@emotion/styled";

import { color } from "metabase/lib/colors";

export interface IGroupCardProps {
  withOpacity?: boolean;
  isDragging?: boolean;
}

export interface IDraggableButtonProps {
  isDragging?: boolean;
  isHover?: boolean;
}

export const StyledGroupCard = styled.div<IGroupCardProps>`
  position: relative;
  flex-basis: 322px;
  flex-grow: 0;
  flex-shrink: 0;
  border: 1px solid ${color("border")};
  border-radius: 8px;
  background-color: ${color("white")};
  opacity: ${props => (props.withOpacity ? "0.8" : "1")};
  box-shadow: ${props =>
    props.isDragging
      ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
      : "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px"};
  transform: ${props => (props.isDragging ? "scale(1.05)" : "scale(1)")};
`;

export const DraggableButton = styled.button<IDraggableButtonProps>`
  opacity: ${props => (props.isHover ? "1" : "0")};
  position: absolute;
  top: 1.35rem;
  left: 0.7rem;
  background-color: ${color("white")};
  cursor: ${props => (props.isDragging ? "grabbing" : "grab")};
  transition: all 0.3s ease;
`;

// visibility: ${props => (props.isHover ? "visible" : "hidden")};
