import styled from "@emotion/styled";

import { color } from "metabase/lib/colors";

export interface IDisabledProps {
  disabled?: boolean;
}

export const CardFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 0.6rem 0.9rem;
  border-top: 1px solid ${color("border")};
`;

export const CardFooterButton = styled.button<IDisabledProps>`
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: ${props =>
    props.disabled ? "rgba(105, 110, 123, 0.8)" : color("text-medium")};
  cursor: pointer;
`;
