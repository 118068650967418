import type {
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier,
} from "@dnd-kit/core";
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useEffect, useState } from "react";

import { useSelector } from "metabase/lib/redux";
import {
  getCurrentEventProperties,
  getCurrentEvents,
} from "metabase/query_builder/selectors";

import { GroupCard } from "../GroupCard/GroupCard";

import { GroupCardsList, StyledGroupCards } from "./GroupCards.styled";

// interface IGroupCardData {
//   title: string;
//   text: string;
// }

// interface IGroupCard {
//   id: number;
//   title: string;
//   data: IGroupCardData[];
// }
interface IGroupCardsProps {
  onFilterChange: () => void;
  onGroupChange: () => void;
  onCohortChange?: () => void;
  onDelete: (cardId: string) => void;
}

export const GroupCards = ({
  onFilterChange,
  onGroupChange,
  onCohortChange,
  onDelete,
}: IGroupCardsProps) => {
  const currentProperties = useSelector(getCurrentEventProperties);
  const currentItems = useSelector(getCurrentEvents);
  const [items, setItems] = useState(currentItems);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  useEffect(() => {
    setItems(currentItems);
  }, [currentItems]);

  const getItems = (id: UniqueIdentifier): number =>
    items.findIndex(item => item.id === id);

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems(items => {
        const originalPosition = getItems(active.id);
        const newPosition = getItems(over?.id);

        return arrayMove(items, originalPosition, newPosition);
      });
    }

    setActiveId(null);
  };

  const handleDragStart = (event: DragStartEvent): void => {
    setActiveId(event.active.id);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
    >
      <StyledGroupCards>
        <SortableContext items={items} strategy={horizontalListSortingStrategy}>
          <GroupCardsList>
            {items.map((item, index) => {
              return (
                <GroupCard
                  key={item.id}
                  item={item}
                  index={index}
                  onFilterChange={onFilterChange}
                  onGroupChange={onGroupChange}
                  onCohortChange={onCohortChange}
                  onDelete={onDelete}
                  currentProperties={currentProperties}
                />
              );
            })}
          </GroupCardsList>
        </SortableContext>
      </StyledGroupCards>
      <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
        {activeId ? (
          <GroupCard
            key={activeId}
            item={items.find(item => item.id === activeId)}
            isDragging
            onFilterChange={onFilterChange}
            onGroupChange={onGroupChange}
            onCohortChange={onCohortChange}
            currentProperties={currentProperties}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};
