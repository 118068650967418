import { ModalList } from "../ModalList";

import {
  DropdownCounter,
  DropdownGroup,
  DropdownHeader,
  DropdownHeading,
  DropdownIcon,
} from "./ModalDropdownList.styled";

interface IModalItem {
  title: string;
  options: string[];
}

interface IModalDropdownListProps {
  items: IModalItem[];
}

export const ModalDropdownList = ({ items }: IModalDropdownListProps) => {
  return (
    <div>
      {items.map((item, index) => {
        return (
          <DropdownGroup key={index}>
            <DropdownHeader>
              <DropdownIcon name="chevrondown" size={12} />
              <DropdownHeading>{item.title}</DropdownHeading>
              <DropdownCounter>{item.options.length}</DropdownCounter>
            </DropdownHeader>
            <ModalList items={item.options} isCheckboxMode={true} />
          </DropdownGroup>
        );
      })}
    </div>
  );
};
