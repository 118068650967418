import {
  GroupLinkIcon,
  GroupLinkTextContainer,
  StyledGroupLink,
} from "./GroupLink.styled";

interface IGroupLinkProps {
  name: string;
  onClick: () => void;
}

export const GroupLink = ({ name, onClick }: IGroupLinkProps) => {
  return (
    <StyledGroupLink type="button" onClick={onClick}>
      <GroupLinkTextContainer>
        <GroupLinkIcon name={"add"} size={16} /> {name}
      </GroupLinkTextContainer>
    </StyledGroupLink>
  );
};
