import { useContext } from "react";

import { Icon } from "metabase/ui";

import { CardsContext } from "../../contexts/CardsContext";

import { CardFooterButton, CardFooterWrapper } from "./CardFooter.styled";

interface ICardFooterProps {
  onFilterChange: () => void;
  onGroupChange: () => void;
  onCohortChange?: () => void;
}

export const CardFooter = ({
  onFilterChange,
  onGroupChange,
  onCohortChange,
}: ICardFooterProps) => {
  const { cohortOption, groupOption, filterOption } = useContext(CardsContext);

  return (
    <CardFooterWrapper>
      {filterOption && (
        <CardFooterButton onClick={onFilterChange}>
          <Icon name={"add"} size={12} />
          {"Filter by"}
        </CardFooterButton>
      )}

      {groupOption && (
        <CardFooterButton onClick={onGroupChange}>
          <Icon name={"add"} size={12} />
          {"Group by"}
        </CardFooterButton>
      )}

      {cohortOption && (
        <CardFooterButton onClick={onCohortChange} disabled={false}>
          <Icon name={"add"} size={12} />
          {"In Cohort"}
        </CardFooterButton>
      )}
    </CardFooterWrapper>
  );
};
