import styled from "@emotion/styled";

import Button from "metabase/core/components/Button";
import { color } from "metabase/lib/colors";
import { Icon } from "metabase/ui";

export const StyledGroupLink = styled(Button)`
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 700;
  border: none;
  color: ${color("brand")};
`;

export const GroupLinkTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const GroupLinkIcon = styled(Icon)`
  color: ${color("brand")};
`;
