import { useState } from "react";

import { Icon } from "metabase/ui";

import {
  SidebarContainer,
  SidebarIconContainer,
  SidebarItem,
  SidebarText,
} from "./VisualizationSidebar.styled";

const tabsData = [
  {
    id: "tab-segmentation",
    value: "Segmentation",
    icon: "pulse",
  },
  {
    id: "tab-funnel",
    value: "Funnel",
    icon: "funnel",
  },
  {
    id: "tab-sessions",
    value: "Sessions",
    icon: "clock",
  },
];

export const VisualizationSidebar = () => {
  const [activeTab, setActiveTab] = useState(tabsData[1].value);

  return (
    <SidebarContainer>
      {tabsData.map(tab => {
        return (
          <SidebarItem key={tab.id} onClick={() => setActiveTab(tab.value)}>
            <SidebarIconContainer active={activeTab === tab.value}>
              <Icon name={tab.icon} size={23} />
            </SidebarIconContainer>
            <SidebarText>{tab.value}</SidebarText>
          </SidebarItem>
        );
      })}
    </SidebarContainer>
  );
};
