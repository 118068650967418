import { useState } from "react";

import CheckBox from "metabase/core/components/CheckBox";
import { color } from "metabase/lib/colors";
import { useDispatch } from "metabase/lib/redux";
import { setEventsProperties } from "metabase/query_builder/actions";
import { Icon, Modal, Title } from "metabase/ui";

import { CancelButton } from "../CancelButton";
import { ModalCheckboxes } from "../ModalCheckboxes";
import { SaveButton } from "../SaveButton";

import {
  CheckboxContainer,
  ClearButton,
  ConditionContent,
  ConditionTitle,
  CopyButton,
  DashboardModalFooter,
  DashboardTextInput,
  ModalConditionColumn,
  ModalContent,
  ModalListItem,
  ModalSelectedColumn,
  ModalWrapper,
  SelectedContent,
  SelectedGroup,
  SelectedHeader,
  SelectedTitle,
  StyledModalList,
} from "./ParamsModal.styled";

interface IGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  additionalCancelOption?: boolean;
}

export const ParamsModal = ({
  isOpen,
  onClose,
  additionalCancelOption,
}: IGroupModalProps) => {
  const dispatch = useDispatch();
  const [propertyCondition, setPropertyCondition] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({
    checkbox1: false,
    checkbox2: false,
  });
  const conditionOptions = [
    {
      display: "= (is)",
      value: "=",
    },
    {
      display: "≠ (is not)",
      value: "≠",
    },
    {
      display: "starts with",
      value: "starts with",
    },
    {
      display: "does not start with",
      value: "does not start with",
    },
    {
      display: "∋ (contains)",
      value: "∋",
    },
    {
      display: "∌ (does not contain)",
      value: "∌",
    },
    {
      display: "< (less)",
      value: "<",
    },
    {
      display: "≤ (less or equal)",
      value: "≤",
    },
    {
      display: "> (greater)",
      value: ">",
    },
    {
      display: "≥ (greater or equal)",
      value: "≥",
    },
    {
      display: "{=} (set is)",
      value: "{=}",
    },
    {
      display: "{≠} (set is not)",
      value: "{≠}",
    },
    {
      display: "{∋} (set contains)",
      value: "{∋}",
    },
    {
      display: "{∌} (set does not contain)",
      value: "{∌}",
    },
    {
      display: "✱ (glob match)",
      value: "✱",
    },
    {
      display: "!✱ (glob does not match)",
      value: "!✱",
    },
  ];

  const handleChange = e => {
    const { name, checked } = e.target;

    setSelectedOptions(prevState => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleClearClick = () => {
    setSelectedOptions({
      checkbox1: false,
      checkbox2: false,
    });
  };

  const handleSaveClick = () => {
    dispatch(
      setEventsProperties({
        condition: propertyCondition,
      }),
    );
  };

  return (
    <Modal.Root opened={isOpen} onClose={() => console.log("close")}>
      <Modal.Overlay />
      <ModalContent>
        <ModalWrapper>
          <ModalConditionColumn>
            <ConditionTitle order={2} size="1rem" lh="1rem" color="inherit">
              Сondition
            </ConditionTitle>
            <ConditionContent>
              <StyledModalList>
                {conditionOptions.map((option, index) => {
                  return (
                    <ModalListItem
                      isActive={
                        propertyCondition === option.value ? true : false
                      }
                      key={index}
                      onClick={() => setPropertyCondition(option.value)}
                    >
                      {option.display}
                    </ModalListItem>
                  );
                })}
              </StyledModalList>
            </ConditionContent>
          </ModalConditionColumn>
          <ModalSelectedColumn>
            <DashboardTextInput
              type="search"
              icon={<Icon name="search" size={16} />}
              miw={400}
              value={""}
              onChange={e => console.log("searching")}
              placeholder={"Search…"}
            />
            <SelectedGroup>
              <SelectedHeader>
                <Title order={2} size="1rem" lh="1rem" color="inherit">
                  Selected
                </Title>
                <ClearButton onClick={handleClearClick}>Clear</ClearButton>
                <CopyButton>Copy Selected</CopyButton>
              </SelectedHeader>
              <CheckboxContainer>
                <CheckBox
                  name={"checkbox1"}
                  checked={selectedOptions.checkbox1}
                  onChange={handleChange}
                  aria-label={`539`}
                  label={"539"}
                  uncheckedColor={color("border")}
                />
              </CheckboxContainer>
              <CheckboxContainer>
                <CheckBox
                  name={"checkbox2"}
                  checked={selectedOptions.checkbox2}
                  onChange={handleChange}
                  aria-label={`225`}
                  label={"225"}
                  uncheckedColor={color("border")}
                />
              </CheckboxContainer>
            </SelectedGroup>
            <SelectedContent>
              <SelectedTitle order={2} size="1rem" lh="1rem" color="inherit">
                All values
              </SelectedTitle>
              <ModalCheckboxes />
            </SelectedContent>
          </ModalSelectedColumn>
        </ModalWrapper>

        <DashboardModalFooter fullPageModal={false} formModal={true}>
          {additionalCancelOption && <CancelButton onClose={onClose} />}
          <SaveButton onClick={handleSaveClick} />
        </DashboardModalFooter>
      </ModalContent>
    </Modal.Root>
  );
};
