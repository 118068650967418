import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useState } from "react";

import { Icon } from "metabase/ui";

import { CardContent } from "../CardContent/CardContent";
import { CardFooter } from "../CardFooter";
import { CardHeader } from "../CardHeader";
import { ParamsModal } from "../ParamsModal";

import { DraggableButton, StyledGroupCard } from "./GroupCard.styled";

interface IGroupCardData {
  title: string;
  text: string;
}

interface IGroupCard {
  id: string;
  display: string;
  value: string;
  data: IGroupCardData[];
}

interface IcurrentProperties {
  condition: string;
}

interface IGroupCardProps {
  item: IGroupCard;
  index: number;
  onFilterChange: () => void;
  onGroupChange: () => void;
  onCohortChange?: () => void;
  withOpacity?: boolean;
  isDragging?: boolean;
  onDelete: (cardId: string) => void;
  currentProperties: IcurrentProperties;
}

export const GroupCard = ({
  item,
  index,
  isDragging,
  onFilterChange,
  onGroupChange,
  onCohortChange,
  onDelete,
  currentProperties,
}: IGroupCardProps) => {
  const { id, value, data } = item;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const [isParamsModalOpen, setIsParamsModalOpen] = useState(false);
  const [isCardHover, setIsCardHover] = useState(false);

  const style = {
    transition: transition || undefined,
    transform: CSS.Transform.toString(transform),
  };

  const onParamsModalClose = () => {
    setIsParamsModalOpen(false);
  };

  const onParamsModalOpen = () => {
    setIsParamsModalOpen(true);
  };

  const handleCardHover = () => {
    setIsCardHover(true);
  };

  const handleCardLeave = () => {
    setIsCardHover(false);
  };

  return (
    <>
      <StyledGroupCard
        ref={setNodeRef}
        style={style}
        withOpacity={isDragging}
        onMouseOver={handleCardHover}
        onMouseOut={handleCardLeave}
      >
        <DraggableButton
          {...attributes}
          {...listeners}
          isDragging={isDragging}
          isHover={isCardHover}
        >
          <Icon name={"draggable"} size={16} />
        </DraggableButton>
        <CardHeader
          isHover={isCardHover}
          index={index}
          onDelete={onDelete}
          cardId={item.id}
        >
          {value}
        </CardHeader>
        <CardContent
          data={data}
          onParamsClick={onParamsModalOpen}
          currentProperties={currentProperties}
        ></CardContent>
        <CardFooter
          onFilterChange={onFilterChange}
          onGroupChange={onGroupChange}
          onCohortChange={onCohortChange}
        ></CardFooter>
      </StyledGroupCard>

      <ParamsModal
        isOpen={isParamsModalOpen}
        onClose={onParamsModalClose}
        additionalCancelOption
      />
    </>
  );
};
