import { useState } from "react";

import { Title } from "metabase/ui";

import { FilterModal } from "../components/FilterModal";
import { GroupButton } from "../components/GroupButton/GroupButton";
import { GroupContainer } from "../components/GroupContainer";

export const GroupSegmentBySection = () => {
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);

  const onGroupModalClose = () => {
    setIsGroupModalOpen(false);
  };

  const onGroupModalOpen = () => {
    setIsGroupModalOpen(true);
  };

  return (
    <GroupContainer>
      <>
        <Title size="0.8rem" lh="1rem" mb={16} fw={900} color="inherit">
          {"Group Segment by"}
        </Title>
        <GroupButton name={"Select user property"} onClick={onGroupModalOpen} />
        <FilterModal
          data={{ title: "Filter by", options: ["option 1", "option 2"] }}
          isOpen={isGroupModalOpen}
          onClose={onGroupModalClose}
        />
      </>
    </GroupContainer>
  );
};
