import { useState } from "react";

import { Icon, Title } from "metabase/ui";

import { GroupContainer } from "../components/GroupContainer";
import { SelectModal } from "../components/SelectModal";
import { color } from "metabase/lib/colors";

import {
  SelectButton,
  StyledButton,
  TabsContainer,
} from "./MeasuredAsSection.styled";

export const MeasuredAsSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const tabsList = [
    "Conversion",
    "Over Time",
    "Time to Convert",
    "Significance",
  ];

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <GroupContainer>
      <>
        <Title size="0.8rem" lh="1rem" mb={16} fw={900} color="inherit">
          {"Measuerd As"}
        </Title>
        <SelectButton onClick={onModalOpen}>
          Completed within 1 days
          <Icon name="chevrondown" size={10} color={color("brand")} />
        </SelectButton>
        <TabsContainer>
          {tabsList.map((tab, index) => {
            return (
              <StyledButton
                key={index}
                isActive={activeTab === index ? true : false}
                type="button"
                onClick={() => setActiveTab(index)}
              >
                {tab}
              </StyledButton>
            );
          })}
        </TabsContainer>
        <SelectModal isOpen={isModalOpen} onClose={onModalClose} />
      </>
    </GroupContainer>
  );
};
