import { StyledCancelButton } from "./CancelButton.styled";

interface ICancelButtonProps {
  onClose?: () => void;
}

export const CancelButton = ({ onClose }: ICancelButtonProps) => {
  return (
    <StyledCancelButton type="button" onClick={onClose}>
      {"Cancel"}
    </StyledCancelButton>
  );
};
