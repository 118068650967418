import { useState } from "react";

import {
  ModalListCheckbox,
  ModalListItem,
  StyledIcon,
  StyledModalList,
} from "./ModalList.styled";

interface IModalListProps {
  items: string[];
  isCheckboxMode?: boolean;
}

export const ModalList = ({
  items,
  isCheckboxMode = false,
}: IModalListProps) => {
  const [selectedOptions, setSelectedOptions] = useState([false, false, false]);

  const handleChange = (itemIndex: number) => {
    const newArray = selectedOptions.map((option, index) => {
      if (index === itemIndex) {
        return !option;
      }

      return option;
    });

    setSelectedOptions(newArray);
  };

  return (
    <StyledModalList>
      {items.map((item, index) => {
        return (
          <ModalListItem key={index} onClick={() => handleChange(index)}>
            {isCheckboxMode ? (
              <ModalListCheckbox>
                {item}
                {selectedOptions[index] && (
                  <StyledIcon name="check" size={10} />
                )}
              </ModalListCheckbox>
            ) : (
              { item }
            )}
          </ModalListItem>
        );
      })}
    </StyledModalList>
  );
};
