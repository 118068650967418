import { Icon } from "metabase/ui";

import {
  CardHeaderDelete,
  CardHeaderNumber,
  CardHeaderTitle,
  CardHeaderWrapper,
} from "./CardHeader.styled";

interface ICardHeaderProps {
  children: JSX.Element | string;
  index: number;
  isHover: boolean;
  onDelete: (cardId: string) => void;
  cardId: string;
}

export const CardHeader = ({
  children,
  index,
  isHover,
  onDelete,
  cardId,
}: ICardHeaderProps) => {
  const cardNumber = index + 1;

  return (
    <CardHeaderWrapper>
      <CardHeaderNumber isHover={isHover}>#{cardNumber}</CardHeaderNumber>
      <CardHeaderTitle>{children}</CardHeaderTitle>
      <CardHeaderDelete onClick={() => onDelete(cardId)}>
        <Icon name={"trash"} size={14} />
      </CardHeaderDelete>
    </CardHeaderWrapper>
  );
};
