import styled from "@emotion/styled";

import Button from "metabase/core/components/Button";
import { color } from "metabase/lib/colors";

export const StyledSaveButton = styled(Button)`
  margin-left: 0;
  padding: 0.5rem 1.5rem;
  color: ${color("white")};
  background-color: ${color("brand")};
  border-color: ${color("brand")};
`;
