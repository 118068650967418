import { createAction } from "redux-actions";

import { createThunkAction } from "metabase/lib/redux";
import type {
  Dispatch,
  GetState,
  QueryBuilderUIControls,
} from "metabase-types/store";

export const GET_EVENTS_LIST = "metabase/qb/GET_EVENTS_LIST";
export const fetchEvents = createThunkAction(GET_EVENTS_LIST, () => {
  console.log("fetch");
  return async dispatch => {
    const action = await dispatch([
      {
        id: "1",
        display: "Catalog - screen view",
        value: "Catalog - screen view",
      },
      {
        id: "2",
        display: "content_type",
        value: "content_type",
      },
    ]);

    return action;
  };
});

export const GET_EVENTS_PROPERTIES = "metabase/qb/GET_EVENTS_PROPERTIES";
export const fetchEventProperties = createThunkAction(
  GET_EVENTS_PROPERTIES,
  () => {
    console.log("fetch props");
    return async dispatch => {
      const action = await dispatch([
        {
          id: "1",
          type: "string",
          value: "categoryTitle",
          display: "categoryTitle",
        },
        {
          id: "2",
          type: "empty",
          value: "categoryTitle",
          display: "categoryTitle",
        },
      ]);

      return action;
    };
  },
);

export const ADD_EVENT = "metabase/qb/ADD_EVENT";

export const addEvent = createAction(ADD_EVENT);

export const REMOVE_EVENT = "metabase/qb/REMOVE_EVENT";

export const removeEvent = createAction(REMOVE_EVENT);

export const UPDATE_CARDS = "metabase/qb/UPDATE_CARDS";

export const updateCard = createAction(UPDATE_CARDS);

export const SET_EVENTS_PROPERTIES = "metabase/qb/SET_EVENTS_PROPERTIES";

export const setEventsProperties = createAction(SET_EVENTS_PROPERTIES);
