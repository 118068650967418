import { Input, Modal, Select, Title } from "metabase/ui";

import { ModalDropdownList } from "../ModalDropdownList";

import {
  CancelButton,
  DashboardModalFooter,
  DashboardModalHeader,
  EventsModalContent,
  ModalCloseButton,
  ModalContent,
  ModalDescription,
  ModalInputsContainer,
  SaveButton,
  StyledNumberInput,
  StyledSelect,
} from "./SelectModal.styled";

interface ISelectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SelectModal = ({ isOpen, onClose }: ISelectModalProps) => {
  return (
    <Modal.Root opened={isOpen} onClose={() => console.log("close")}>
      <Modal.Overlay />
      <ModalContent>
        <DashboardModalHeader>
          <Title order={2} size="1.5rem" lh="1.6rem" color="inherit">
            {"Completed within"}
          </Title>
          <ModalCloseButton onClick={onClose} />
        </DashboardModalHeader>
        <EventsModalContent>
          <ModalInputsContainer>
            <StyledNumberInput min={1} defaultValue={1} />
            <StyledSelect
              p={12}
              value={"days"}
              data={[
                { value: "days", label: "days" },
                { value: "weeks", label: "weeks" },
              ]}
              onChange={(value: "") => console.log(value)}
              styles={{
                dropdown: {
                  maxHeight: 100,
                  padding: "10px",
                  overflowY: "auto",
                  color: "white",
                  backgroundColor: "white",
                },
                root: {
                  padding: "0.1rem",
                },
                input: {
                  borderRadius: "8px",
                  padding: "12px",
                },
                item: {
                  background: "transparent",
                  borderColor: "transparent",
                  marginBottom: "0",
                },
                label: {
                  background: "white",
                },
                icon: {
                  color: "#509EE3",
                  fill: "#509EE3",
                  width: "12x",
                  height: "12px",
                },
                rightSection: {
                  color: "#509EE3",
                  fill: "#509EE3",
                },
              }}
            />
          </ModalInputsContainer>
          <ModalDescription>
            Time to convert is calculated using the first completion event.
          </ModalDescription>
        </EventsModalContent>
        <DashboardModalFooter fullPageModal={false} formModal={true}>
          <CancelButton type="button" onClick={() => console.log("click")}>
            {"Cancel"}
          </CancelButton>
          <SaveButton type="button" onClick={() => console.log("click")}>
            {"Save"}
          </SaveButton>
        </DashboardModalFooter>
      </ModalContent>
    </Modal.Root>
  );
};
