import styled from "@emotion/styled";

import { color } from "metabase/lib/colors";
import { Icon } from "metabase/ui";

export interface ISelectedProps {
  selected?: boolean;
}

export const CardContentWrapper = styled.div``;

export const CardContentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid ${color("border")};

  &:last-child {
    border-bottom: none;
  }
`;

export const CardContentColumn = styled.div``;

export const CardContentDeleteButton = styled.button`
  cursor: pointer;
`;

export const CardContentTitle = styled.h3`
  margin-bottom: 10px;
  font-size: 0.81rem;
  line-height: 0.95rem;
  font-weight: 500;
  color: ${color("text-medium")};
  opacity: 0.8;
`;

export const CardContentText = styled.button<ISelectedProps>`
  margin: 0;
  font-size: 0.81rem;
  line-height: 0.95rem;
  font-weight: 700;
  color: ${props => (props.selected ? color("brand") : color("text-medium"))};
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
  color: ${color("text-medium")};
`;
