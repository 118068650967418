import { useState } from "react";

import CheckBox from "metabase/core/components/CheckBox";
import { color } from "metabase/lib/colors";

import {
  SelectAllContainer,
  CheckboxesList,
  CheckboxContainer,
  StyledCheckBox,
} from "./ModalCheckboxes.styled";

interface IModalCheckboxesProps {
  onCheckboxChange: (id: string[]) => void;
}

export const ModalCheckboxes = ({
  onCheckboxChange,
}: IModalCheckboxesProps) => {
  const [areAllChecked, setAreAllChecked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    Animals: false,
    finderable: false,
    Characters: false,
    Wheels: false,
  });

  const handleChange = e => {
    const { name, checked } = e.target;

    setSelectedOptions(prevState => ({
      ...prevState,
      [name]: checked,
    }));

    const allChecked = Object.values({
      ...selectedOptions,
      [name]: checked,
    }).every(Boolean);

    setAreAllChecked(allChecked);
  };

  const handleSelectAllChange = e => {
    const isChecked = e.target.checked;
    setAreAllChecked(isChecked);

    setSelectedOptions({
      Animals: isChecked,
      finderable: isChecked,
      Characters: isChecked,
      Wheels: isChecked,
    });
  };

  return (
    <>
      <SelectAllContainer>
        <CheckBox
          checked={areAllChecked}
          onChange={handleSelectAllChange}
          aria-label={`Select all items`}
          label={"Select all"}
          uncheckedColor={color("border")}
        />
      </SelectAllContainer>
      <CheckboxesList>
        <CheckboxContainer>
          <CheckBox
            name={"Animals"}
            checked={selectedOptions.checkbox1}
            onChange={handleChange}
            aria-label={`Animals`}
            label={"Animals"}
            uncheckedColor={color("border")}
          />
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            name={"finderable"}
            checked={selectedOptions.checkbox2}
            onChange={handleChange}
            aria-label={`finderable`}
            label={"finderable"}
            uncheckedColor={color("border")}
          />
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            name={"Characters"}
            checked={selectedOptions.checkbox3}
            onChange={handleChange}
            aria-label={`Characters`}
            label={"Characters"}
            uncheckedColor={color("border")}
          />
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckBox
            name={"Wheels"}
            checked={selectedOptions.checkbox4}
            onChange={handleChange}
            aria-label={`Wheels`}
            label={"Wheels"}
            uncheckedColor={color("border")}
          />
        </CheckboxContainer>
      </CheckboxesList>
    </>
  );
};
