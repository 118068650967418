import styled from "@emotion/styled";

export const StyledGroupCards = styled.div`
  max-height: 264px;
  width: 1340px;
  padding-bottom: 1rem;
  padding-right: 1rem;
  margin-bottom: 1.25rem;
`;

export const GroupCardsList = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 6px;
    min-width: 10px;
    width: 464px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #a4aab8;
  }
`;
