import { useContext, useState } from "react";

import { useDispatch, useSelector } from "metabase/lib/redux";
import { CardsContext } from "../contexts/CardsContext";
import { getEventsList } from "metabase/query_builder/selectors";
import { Title } from "metabase/ui";

import { AddTaskModal } from "../components/AddTaskModal";
import { FilterModal } from "../components/FilterModal";
import { GroupButton } from "../components/GroupButton/GroupButton";
import { GroupCards } from "../components/GroupCards";
import { GroupContainer } from "../components/GroupContainer";
import { GroupLink } from "../components/GroupLink";

export const SegmentBySection = () => {
  const dispatch = useDispatch();
  const events = useSelector(getEventsList);
  const SegmentContext = useContext(CardsContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isCohortModalOpen, setIsCohortModalOpen] = useState(false);
  const [eventsList, setEventsList] = useState([]);

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onFilterModalClose = () => {
    setIsFilterModalOpen(false);
  };

  const onFilterModalOpen = () => {
    setIsFilterModalOpen(true);
  };

  const onCohortModalClose = () => {
    setIsCohortModalOpen(false);
  };

  const onCohortModalOpen = () => {
    setIsCohortModalOpen(true);
  };

  const addSegmentCard = (cardId: string) => {
    const newCard = events.find(event => event.id === cardId);

    // dispatch(addEvent(newCard));
  };

  return (
    <GroupContainer>
      <>
        <Title size="0.8rem" lh="1rem" mb={16} fw={900} color="inherit">
          {"Segment by"}
        </Title>
        <CardsContext.Provider
          value={{ ...SegmentContext, groupOption: false, cohortOption: true }}
        >
          {/* <GroupCards
            data={[
              {
                id: 1,
                title: "Catalog - screen view",
                data: [
                  { title: "theme", text: "Animals" },
                  { title: "theme", text: "Animals" },
                ],
              },
              {
                id: 2,
                title: "content_type",
                data: [{ title: "source", text: "theme" }],
              },
            ]}
            onFilterChange={onFilterModalOpen}
            onGroupChange={onCohortModalOpen}
            onCohortChange={onCohortModalOpen}
          /> */}
        </CardsContext.Provider>
        {eventsList.length ? (
          <GroupLink name={"Add events"} onClick={onModalOpen} />
        ) : (
          <GroupButton name={"Add segment"} onClick={onModalOpen} />
        )}
        <AddTaskModal
          title={"Add Segments"}
          data={events}
          isOpen={isModalOpen}
          onClose={onModalClose}
          onSave={addSegmentCard}
        />
        <FilterModal
          data={{ title: "Filter by", options: ["option 1", "option 2"] }}
          isOpen={isFilterModalOpen}
          onClose={onFilterModalClose}
        />
        <FilterModal
          data={{ title: "Select cohort", options: ["option 1", "option 2"] }}
          isOpen={isCohortModalOpen}
          onClose={onCohortModalClose}
          additionalCancelOption
        />
      </>
    </GroupContainer>
  );
};
