import styled from "@emotion/styled";

import Button from "metabase/core/components/Button";
import { color } from "metabase/lib/colors";

interface IStyledButtonProps {
  isActive?: boolean;
}

export const TabsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledButton = styled(Button)<IStyledButtonProps>`
  min-width: 146px;
  background-color: ${props =>
    props.isActive ? color("brand") : color("white")};
  border-color: ${props => (props.isActive ? color("brand") : color("border"))};
  color: ${props => (props.isActive ? color("white") : color("text-dark"))};
`;

export const SelectButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 302px;
  padding: 3px 8px;
  border: 1px solid #eaf5fb;
  border-radius: 8px;
  color: inherit;
  background-color: ${color("white")};
  cursor: pointer;
`;
