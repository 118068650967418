import styled from "@emotion/styled";

import { Select } from "metabase/ui";

export const StyledGroupSelect = styled(Select)`
  width: 245px;
  border-radius: 8px;
  color: inherit;

  input {
    height: 1.4rem;
    min-height: 1.4rem;
    padding-top: 3px;
    padding-bottom: 3px;
    border-color: rgba(80, 158, 227, 0.2);
  }
`;
