import styled from "@emotion/styled";

import { color } from "metabase/lib/colors";
import { Text } from "metabase/ui";

export interface IActiveProps {
  active?: boolean;
}

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  width: 93px;
  padding: 16px;
  text-align: center;
  border-right: 1px solid ${color("border")};
  border-bottom: 1px solid ${color("border")};
  background-color: ${color("bg-light")};
`;

export const SidebarItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const SidebarIconContainer = styled.div<IActiveProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  margin-bottom: 4px;
  border-radius: 6px;
  background-color: ${props => (props.active ? color("brand") : "transparent")};

  svg {
    fill: ${props => (props.active ? color("white") : color("text-medium"))};
  }
`;

export const SidebarText = styled(Text)`
  font-size: 0.6rem;
  line-height: 0.7rem;
`;
