import { StyledSaveButton } from "./SaveButton.styled";

interface ISaveButtonProps {
  onClick?: () => void;
}

export const SaveButton = ({ onClick }: ISaveButtonProps) => {
  return (
    <StyledSaveButton type="button" onClick={onClick}>
      {"Save"}
    </StyledSaveButton>
  );
};
