import styled from "@emotion/styled";

import Button from "metabase/core/components/Button";
import { color } from "metabase/lib/colors";

export const StyledGroupButton = styled(Button)`
  min-width: 300px;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  color: ${color("white")};
  background-color: ${color("brand")};
  border-color: ${color("brand")};
`;

export const GroupButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
