import styled from "@emotion/styled";

import { color } from "metabase/lib/colors";

import Button from "metabase/core/components/Button";

export const StyledCancelButton = styled(Button)`
  padding: 0.5rem 1.5rem;
  color: ${color("text-dark")};
  background-color: ${color("white")};
  border-color: ${color("border")};
`;
