import {
  CardContentColumn,
  CardContentDeleteButton,
  CardContentItem,
  CardContentText,
  CardContentTitle,
  CardContentWrapper,
  StyledIcon,
} from "./CardContent.styled";

// interface IGroupCardItemData {
//   title: string;
//   text: string;
// }

interface IGroupCardData {
  title: string;
  text: string;
  // data: IGroupCardItemData[];
}

interface IcurrentProperties {
  condition: string;
}

interface ICardContentProps {
  data: IGroupCardData[];
  onParamsClick: () => void;
  currentProperties: IcurrentProperties;
}

export const CardContent = ({
  data,
  onParamsClick,
  currentProperties,
}: ICardContentProps) => {
  return (
    <CardContentWrapper>
      {data.map((item, index) => {
        return (
          <CardContentItem key={index}>
            <CardContentColumn>
              <CardContentTitle>{item.title}</CardContentTitle>
              <CardContentText onClick={onParamsClick}>
                {`${currentProperties.condition} ${item.title}`}
              </CardContentText>
            </CardContentColumn>
            <CardContentColumn>
              <CardContentDeleteButton>
                <StyledIcon name={"close"} size={16} />
              </CardContentDeleteButton>
            </CardContentColumn>
          </CardContentItem>
        );
      })}
    </CardContentWrapper>
  );
};
