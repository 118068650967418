import styled from "@emotion/styled";

export const SelectAllContainer = styled.div`
  padding-left: 1.5rem;
  margin-bottom: 12.5px;

  &:hover {
    background-color: #eaf5fb;
  }
`;

export const CheckboxesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CheckboxContainer = styled.div`
  padding-left: 32px;
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  padding-right: 8px;

  &:hover {
    background-color: #eaf5fb;
  }
`;
