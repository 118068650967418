import { useContext, useEffect, useState } from "react";

import { useDispatch, useSelector } from "metabase/lib/redux";
import {
  addEvent,
  fetchEvents,
  removeEvent,
} from "metabase/query_builder/actions";
import {
  getCurrentEvents,
  getEventsList,
} from "metabase/query_builder/selectors";
import { Title } from "metabase/ui";

import { AddTaskModal } from "../components/AddTaskModal";
import { FilterModal } from "../components/FilterModal";
import { GroupButton } from "../components/GroupButton/GroupButton";
import { GroupCards } from "../components/GroupCards";
import { GroupContainer } from "../components/GroupContainer";
import { GroupLink } from "../components/GroupLink";
import { GroupModal } from "../components/GroupModal";
import { GroupSelect } from "../components/GroupSelect";
import { CardsContext } from "../contexts/CardsContext";

import { EventsHeaderWrapper } from "./EventsSection.styled";

export const EventsSection = () => {
  const dispatch = useDispatch();
  const events = useSelector(getEventsList);
  const currentEvents = useSelector(getCurrentEvents);
  const EventsContext = useContext(CardsContext);
  const [eventsOrder, setEventsOrder] = useState("In this order");
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const eventOrderOptions = [
    { id: 1, value: "In this order", label: "In this order" },
    { id: 2, value: "In that order", label: "In that order" },
  ];

  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  const onTaskModalClose = () => {
    setIsTaskModalOpen(false);
  };

  const onTaskModalOpen = () => {
    setIsTaskModalOpen(true);
  };

  const onFilterModalClose = () => {
    setIsFilterModalOpen(false);
  };

  const onFilterModalOpen = () => {
    setIsFilterModalOpen(true);
  };

  const onGroupModalClose = () => {
    setIsGroupModalOpen(false);
  };

  const onGroupModalOpen = () => {
    setIsGroupModalOpen(true);
  };

  const addEventCard = (cardId: string) => {
    const newCard = events.find(event => event.id === cardId);

    dispatch(addEvent(newCard));
  };

  const removeEventCard = (cardId: string) => {
    dispatch(removeEvent(cardId));
  };

  return (
    <GroupContainer>
      <>
        <EventsHeaderWrapper>
          <Title size="0.8rem" lh="1rem" fw={900} color="inherit">
            {"Events"}
          </Title>
          <GroupSelect
            value={eventsOrder}
            data={eventOrderOptions}
            onChange={value => setEventsOrder(value)}
          />
        </EventsHeaderWrapper>
        <CardsContext.Provider value={{ ...EventsContext }}>
          <GroupCards
            onFilterChange={onFilterModalOpen}
            onGroupChange={onGroupModalOpen}
            onDelete={removeEventCard}
          />
        </CardsContext.Provider>
        {currentEvents.length ? (
          <GroupLink name={"Add events"} onClick={onTaskModalOpen} />
        ) : (
          <GroupButton name={"Add events"} onClick={onTaskModalOpen} />
        )}
        <AddTaskModal
          title={"Add Events"}
          data={events}
          isOpen={isTaskModalOpen}
          onClose={onTaskModalClose}
          onSave={addEventCard}
        />
        <FilterModal
          data={{ title: "Filter by", options: ["option 1", "option 2"] }}
          isOpen={isFilterModalOpen}
          onClose={onFilterModalClose}
        />
        <GroupModal
          data={{ title: "Group by", options: ["option 1", "option 2"] }}
          isOpen={isGroupModalOpen}
          onClose={onGroupModalClose}
        />
      </>
    </GroupContainer>
  );
};
