import { useState } from "react";

import { Icon, Modal, Title } from "metabase/ui";

import { CancelButton } from "../CancelButton";
import { ModalCheckboxes } from "../ModalCheckboxes";
import { SaveButton } from "../SaveButton";

import {
  DashboardModalFooter,
  DashboardModalHeader,
  DashboardTextInput,
  EventsModalContent,
  ModalCloseButton,
  ModalContent,
} from "./FilterModal.styled";

interface IFilterData {
  title: string;
  options: string[];
}

interface IFilterModalProps {
  data: IFilterData;
  isOpen: boolean;
  onClose: () => void;
  additionalCancelOption?: boolean;
}

export const FilterModal = ({
  data,
  isOpen,
  onClose,
  additionalCancelOption,
}: IFilterModalProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  console.log(activeFilters);

  return (
    <Modal.Root opened={isOpen} onClose={() => setSearchValue("")}>
      <Modal.Overlay />
      <ModalContent>
        <DashboardModalHeader>
          <Title order={2} size="1rem" lh="1rem" color="inherit">
            {data.title}
          </Title>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setSearchValue("");
            }}
          />
        </DashboardModalHeader>
        <DashboardTextInput
          type="search"
          icon={<Icon name="search" size={16} />}
          miw={400}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={"Search…"}
        />
        <EventsModalContent>
          <ModalCheckboxes
            onCheckboxChange={(cardId: string[]) => setActiveFilters(cardId)}
          />
        </EventsModalContent>
        <DashboardModalFooter fullPageModal={false} formModal={true}>
          {additionalCancelOption && <CancelButton />}
          <SaveButton />
        </DashboardModalFooter>
      </ModalContent>
    </Modal.Root>
  );
};
