import { createContext } from "react";

export interface ICardsContext {
  value: unknown;
  cohortOption: boolean;
  groupOption: boolean;
  filterOption: boolean;
  onParamsClick: () => void;
}

export const CardsContext = createContext<ICardsContext>({
  value: null,
  cohortOption: false,
  groupOption: true,
  filterOption: true,
  onParamsClick: () => console.log("click"),
});
